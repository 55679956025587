<div class="page-title-area">
    <div class="container">
        <div class="page-title-item" style="margin-bottom: 0px">
            <h1>TARIFS</h1>
            <p>Les tarifs proposés varient de manière dégressive en fonction du nombre de participants.</p>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item">
                    <div class="pricing-top">
                        <h3>750</h3>
						<span>€</span>
                        <p>Pack Solo<br></p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
							<li><b>On s’occupe de vous trouver<br/>vos partenaires de jeu</b></li>
                            <li>2 scènes originales de dialogue<br/>+ 1 monologue</li>
                            <li>1 journée de tournage entouré de l’équipe technique</li>
                            <li>Répétitions avant chaque scène<br/>+ direction de l’acteur</li>
                            <li>Montage des 3 scènes</li>
                            <li>Livraison de la bande démo finale (2mn environ)</li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/contact">Réserver</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item pricing-transform">
                    <div class="pricing-top">
                        <h3>600</h3>
				        <span>€/pers.</span>
                        <p>Pack Duo</p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
                            <li><b>Vous venez déjà accompagné<br/>d’un partenaire de jeu</b></li>
                            <li>2 scènes originales de dialogue<br/>+ 1 monologue</li>
                            <li>1 journée de tournage entouré de l’équipe technique</li>
                            <li>Répétitions avant chaque scène<br/>+ direction de l’acteur</li>
                            <li>Montage des 3 scènes</li>
                            <li>Livraison de la bande démo finale (2mn environ)</li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/contact">Réserver</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="pricing-item">
                    <div class="pricing-top">
                        <h3>500</h3>
				        <span>€/pers.</span>
                        <p>Pack Trio</p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
                            <li><b>Vous venez accompagné<br/>de deux partenaires de jeu</b></li>
                            <li>2 scènes originales de dialogue<br/>+ 1 monologue</li>
                            <li>1 journée de tournage entouré de l’équipe technique</li>
                            <li>Répétitions avant chaque scène<br/>+ direction de l’acteur</li>
                            <li>Montage des 3 scènes</li>
                            <li>Livraison de la bande démo finale (2mn environ)</li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/contact">Réserver</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>