<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Contact</h2>
        </div>
    </div>
</div>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-item">
                    <h2>Réserver</h2>
					<p style="font-size: 20px; color:#000;">
					Pour réserver, rien de plus simple,<br/>
					il suffit de me contacter par email à <a href="mailto:sophie.rms88&#64;gmail.com?subject=Réservation - La Fabrique a Bande démo">sophie.rms88&#64;gmail.com</a><br/><br/>
					
					A très vite avec La Fabrique à Bande démo !
					</p>
                    <!--form id="contactForm">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>
    
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <button type="submit" class="contact-btn btn">Réserver</button>
                            </div>
                        </div>
                    </form-->
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-item">
                    <h2>On garde contact !</h2>

                    <div class="contact-address">
                        <div class="contact-inner">
                            <i class='bx bx-current-location icon'></i>
                            <h4>Adresse:</h4>
                            <span>Nous sommes sur Paris, contactez-nous !</span>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-phone-call icon'></i>
                            <h4>Téléphone:</h4>
                            <a href="tel:0619267851">06 19 26 78 51</a>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-mail-send icon'></i>
                            <h4>Email:</h4>
                            <a href="mailto:sophie.rms88@gmail.com">sophie.rms88&#64;gmail.com</a>
                        </div>

                        <div class="contact-inner">
                            <ul>
                                <li><a href="https://www.instagram.com/sophie.rms/?hl=fr" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

