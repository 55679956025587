<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Videos</h2>
        </div>
    </div>
</div>

<div class="week-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/la-fabrique-a-bande-demo/la-fabrique-a-bande-demo-1.jpg" alt="Week">
					<p align=center>Jérome Debain</p>
                    <a href="https://www.youtube.com/watch?v=U2CdyeJwquc" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/la-fabrique-a-bande-demo/la-fabrique-a-bande-demo-2.jpg" alt="Week">
					<p align=center>Freddy Aurriac</p>
                    <a href="https://www.youtube.com/watch?v=mC4cCedLd6c" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

           
        </div>
    </div>
</div>