<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Testimonial</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Testimonial</li>
            </ul>
        </div>
    </div>
</div>

<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Customer’s Say</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis Risus commodo viverra maecenas accumsan lacus vel facilisis</p>
                <h3>Wahing Bashon</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <h3>Tom Henry</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                <h3>Machiel Shon</h3>
            </div>
        </div>
    </div>
</section>

<section class="service-area service-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Do</span>
            <h2>Our Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-2'></i>
                    <div class="service-inner">
                        <h3>Array Equipment</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-1'></i>
                    <div class="service-inner">
                        <h3>New Technologies</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-theater'></i>
                    <div class="service-inner">
                        <h3>Versatile Actors</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-director-chair'></i>
                    <div class="service-inner">
                        <h3>Top Directions</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-editing'></i>
                    <div class="service-inner">
                        <h3>Video Editing</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-distribution'></i>
                    <div class="service-inner">
                        <h3>Distribution</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>