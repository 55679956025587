<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Videos Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Videos Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="video-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/video-details.jpg" alt="Work">
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                    </div>

                    <div class="video-content">
                        <a class="video-link" routerLink="/videos">Music Video</a>
                        <h2>Shape Of You</h2>
                        <ul>
                            <li><i class='bx bx-video'></i> 523 Views</li>
                            <li><i class='bx bx-comment-detail'></i> <a routerLink="/">4 Comments</a></li>
                        </ul>
                    </div>

                    <div class="video-flim">
                        <h3>Film Story</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</p>
                    </div>

                    <div class="work-details-quote">
                        <i class='bx bxs-quote-left'></i>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab cumque iure reiciendis maiores ipsum itaque placeat rem modi saepe minima sit eveniet officiis magni culpa, impedit explicabo non assumenda.</p>
                        <div class="link">
                            <a routerLink="/">- Tom Henry</a>
                        </div>
                    </div>
                </div>

                <div class="week-area">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="week-item week-video-btn-two">
                                <img src="assets/img/home-one/week4.jpg" alt="Week">
                                <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="week-item week-video-btn-two">
                                <img src="assets/img/home-one/week5.jpg" alt="Week">
                                <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="video-details-item">
                    <div class="video-introduce video-details-bg">
                        <ul>
                            <li>
                                <h4>Client</h4>
                                <span>SK Studio</span>
                            </li>
                            <li>
                                <h4>Date</h4>
                                <span>December 21, 2024</span>
                            </li>
                            <li>
                                <h4>Producer</h4>
                                <span>Tom Henry</span>
                            </li>
                            <li>
                                <h4>Director</h4>
                                <span>Micheal Shon</span>
                            </li>
                            <li>
                                <h4>Manager</h4>
                                <span>Steve Smith</span>
                            </li>
                            <li>
                                <h4>Actors</h4>
                                <span>Brayden, Steffan</span>
                            </li>
                        </ul>
                    </div>

                    <div class="video-social">
                        <span>Share:</span>

                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>