<div class="banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1>La Fabrique à Bande Démo</h1>
                    <p>Réalisation de bande-démos sur mesure</p>
                    <a class="banner-btn" routerLink="/contact">Réserver</a>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-social">
        <span>Restons en contact :&nbsp;</span>
        <ul>
            <!--li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li-->
            <li><a href="https://www.instagram.com/sophie.rms/?hl=fr" target="_blank"><i class='bx bxl-instagram'></i></a></li>
        </ul>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="trust-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
			<span class="sub-title">Pourquoi ?</span>
            <h2>Faire une bande démo</h2>
        </div>
        <div class="trust-content">
            <p>Comédien ? La bande démo constitue votre laisser passer à tous les castings, alors, montrez vous sous votre meilleur jour !</p>
        </div>
    </div>
</section>

<section class="service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title section-title-two">
            <h2>1 jour de tournage, 3 rôles sur mesure,<br/>1 bande démo</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-telephone'></i>
                    <div class="service-inner">
                        <h3>Rendez-vous téléphonique</h3>
                        <p>Premier RDV téléphonique afin d’apprendre à se connaitre et de choisir ensemble le type de scènes qui vous convient le mieux</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-agenda'></i>
                    <div class="service-inner">
                        <h3>Date</h3>
                        <p>On fixe une date de tournage</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-scenario'></i>
                    <div class="service-inner">
                        <h3>Scénario</h3>
                        <p>Vous recevez par mail vos 3 scènes sur mesure, avec la possibilité d’obtenir le contact de vos partenaires de jeu afin de répéter en amont</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-service'></i>
                    <div class="service-inner">
                        <h3>Feuille de service</h3>
                        <p>Vous recevez votre feuille de services avec toutes les infos logistiques</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-tournage'></i>
                    <div class="service-inner">
                        <h3>Jour du tournage</h3>
                        <p>C’est le jour du tournage, à vous de jouer !</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-bandedemo'></i>
                    <div class="service-inner">
                        <h3>Bande démo</h3>
                        <p>Après le montage de vos rushes, vous recevez votre bande-démo finalisée</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="work-area">
    <div class="container-fluid p-0">
        <div class="section-title section-title-two">
			<span class="sub-title">Visionnez</span>
            <h2>Nos réalisations</h2>
        </div>

        <div class="row m-0">
            <div class="col-sm-6 col-lg-6 p-0">
                <a href="https://www.youtube.com/watch?v=U2CdyeJwquc" class="popup-youtube">
                    <div class="work-item">
                        <img src="assets/img/home-two/work1.jpg" alt="Work">
                        <div class="work-content">
                            <h3>Bande démo #1</h3>
                            <span>Jérôme Debain</span>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-sm-6 col-lg-6 p-0">
                <a href="https://www.youtube.com/watch?v=mC4cCedLd6c" class="popup-youtube">
                    <div class="work-item">
                        <img src="assets/img/home-two/work2.jpg" alt="Work">
                       <div class="work-content">
                            <h3>Bande démo #2</h3>
                            <span>Freddy Aurriac</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<!--section class="team-area team-area-two pb-70">
    <div class="container">
        <div class="section-title section-title-two">
            <span class="sub-title">Our Team</span>
            <h2>Directors Of Zico</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team1.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Adlock Prince</h3>
                        <span>Director</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team2.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Tom Henry</h3>
                        <span>Sub. Director</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team3.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Mic John</h3>
                        <span>Actor</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team4.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>John Punk</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<!--section class="testimonial-area ptb-100">
    <div class="container">
        <div class="section-title section-title-two">
            <span class="sub-title">Testimonial</span>
            <h2>What Customer’s Says</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis Risus commodo viverra maecenas accumsan lacus vel facilisis</p>
                <h3>Wahing Bashon</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <h3>Tom Henry</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                <h3>Machiel Shon</h3>
            </div>
        </div>
    </div>
</section-->

<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="section-title section-title-two">
            <span class="sub-title">Découvrez</span>
            <h2>notre savoir-faire</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/home-two/product1.jpg" alt="Product">
                    <h3>Conception</h3>
                    <ul>
                        <li style="font-size: 18px; color:#FFF;">Storyboarding</li>
                        <li style="font-size: 18px; color:#FFF;">Ecriture de scénario</li>
                        <li style="font-size: 18px; color:#FFF;">Direction artistique</li>
						<li style="font-size: 18px; color:#FFF;">Organisation logistique</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/home-two/product2.jpg" alt="Product">
                    <h3>Tournage</h3>
                    <ul>
                        <li style="font-size: 18px; color:#FFF;">Installation technique</li>
                        <li style="font-size: 18px; color:#FFF;">Direction de l'acteur</li>
                        <li style="font-size: 18px; color:#FFF;">Mise en scène</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/home-two/product3.jpg" alt="Product">
                    <h3>Post Production</h3>
                    <ul>
                        <li style="font-size: 18px; color:#FFF;">
</li>
                        <li style="font-size: 18px; color:#FFF;">Dérushage</li>
                        <li style="font-size: 18px; color:#FFF;">Montage</li>
                        <li style="font-size: 18px; color:#FFF;">Étalonnage</li>
                        <li style="font-size: 18px; color:#FFF;">Livraison au format mp4</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!--section class="trust-area pt-100 pb-70">
    <div class="container">
        <div class="section-title section-title-two">
            <span class="sub-title">Trust</span>
            <h2>In Films We Trust</h2>
        </div>
        
        <div class="trust-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

            <div class="row">
                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust1.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust2.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust3.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust4.png" alt="Trust">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->