<div class="page-title-area page-title-area-two">
    <div class="container">
        <div class="page-title-item">
            <h2>À propos</h2>
        </div>
    </div>
</div>

<div class="about-me-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-me-img">
                    <img src="assets/img/about-me2.jpg" alt="About">
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="about-me-content">
                    <h2>Sophie Remise</h2>
                    <ul>
                        <li>Vidéaste</li>
                        <li>Photographe</li>
                    </ul>

                    <p>Videaste depuis plus de 10 ans au service de sujets bien divers tel que l’événementiel, le théâtre, la musique, l’institutionnel, les plateaux TV ou encore le documentaire et le reportage, je me tourne également depuis quelques années vers la fiction et retrouve dans des courts-métrages, séries ou clips mes premiers amours : la joie de raconter une histoire, de mettre en scène des comédiens, de travailler l’esthétisme de l’image du cadre à la lumière…<br/><br/>
J’ai depuis lors à cœur d’aider les autres artistes à se développer en palliant a certains freins que l’on peut rencontrer lorsque l’on débute dans le monde de l’audiovisuel, et notamment les comédiens qui, pour mettre un pied dans le cinéma, ont bien sûr tout d’abord besoin d’images…ça tombe bien, c’est mon rayon !<br/><br/>
C’est ainsi qu’entourée d’autres artistes et techniciens, la fabrique à bande démo voit le jour.</p>

					<p><b style="color:#000">Une équipe dédiée</b></p>
                    <ul>
                        <li>Chef opérateur</li>
                        <li>Ingénieur du son</li>
                        <li>Monteur</li>
						<li>Assistants techniques...</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</div>