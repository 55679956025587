import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-videos-details',
  templateUrl: './videos-details.component.html',
  styleUrls: ['./videos-details.component.scss']
})
export class VideosDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
