<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.svg" alt="Logo" height="28px"></a>
    </div>
    
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.svg" alt="Logo" height="43px"></a>

                
				<div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                
					<ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
						
						<li class="nav-item"><a routerLink="/videos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Réalisations</a></li>
						
						<li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tarifs</a></li>
						
                        <li class="nav-item"><a routerLink="/about-me" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">À propos</a></li>
												
						 <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Réserver</a></li>
                    </ul>
					
						<!--ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page Three</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">About <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/about-me" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Me</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Works <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/works" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Works</a></li>

                                <li class="nav-item"><a routerLink="/works-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Works Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Videos <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/videos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Videos</a></li>

                                <li class="nav-item"><a routerLink="/videos-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/features" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Features</a></li>

                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/partner" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partner</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/timeline" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Timeline</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul-->

                    <div class="side-nav">
                        <div class="nav-srh">
                            <!--div class="search-toggle">
                                <button class="search-icon icon-search"><i class='bx bx-search-alt'></i></button>
                                <button class="search-icon icon-close"><i class='bx bx-x'></i></button>
                            </div>
                            <div class="search-area">
                                <form>
                                    <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                    <button type="submit" name="submit" value="Go" class="search-icon"><i class='bx bx-search-alt'></i></button>
                                </form>
                            </div-->
                        </div>

                        <!--button type="button" class="btn modal-btn" data-bs-toggle="modal" data-bs-target="#myModalRight">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button-->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div id="myModalRight" class="modal fade modal-right" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/img/logo-two.png" alt="Logo">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body">
                <h2>About Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic aliquid quas qui minus! Dolor, ad. Odit, ullam perspiciatis nesciunt numquam explicabo, sunt ipsa libero ipsum maiores officia eius reprehenderit exercitationem.</p>

                <div class="image-area">
                    <h2>Instagram</h2>

                    <div class="row">
                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram1.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram2.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram3.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram4.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram5.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-6 col-lg-4">
                            <a href="#"><img src="assets/img/home-one/instagram6.jpg" alt="Instagram"></a>
                        </div>
                    </div>
                </div>

                <div class="social-area">
                    <h3>Our Social Contact</h3>

                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>